<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>
<template>
    <div class="header">
        <img class="moon_img" src="@/assets/images/night/moon.png" alt="">
        <!-- <div class="text1">{{t('home.title')}}</div> -->
        <div class="text2">
            <img src="@/assets/images/night/logo.png" alt="">
        </div>
    </div>
</template>
<style lang="scss" scoped>

.header {
    text-align: center;
    .moon_img{
        width: 114px;
        height: auto;
    }
    .text1 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 17px;
    }
    .text2 {
        margin-bottom: 30px;
        img {
            width: 238px;
            height: auto;
        }
    }
}

</style>