<script setup>
import loginheader from "./loginheader.vue";
import loginInput from "./loginInput.vue";
import forgetInput from './forgetInput'
import registerInput from './registerInput'
import { ref, computed, watch } from 'vue'

const props = defineProps(['visible', 'code'])
// const emits = defineEmits(['closeDialog'])
const handleCloseMintDialog = ()=>{
    // emits('closeDialog')
    window.closeMLogin();
}

const dialogVisible = computed({
  get() {
    return props.visible;
  },
  set(val) {
    if (!val) {
        window.closeMLogin();
    }
    return val;
  },
});


const shwoType = ref('login')

const handleUserOption = (type) =>{
    shwoType.value = type
}


watch(() =>props.code, (val) =>{
    if (val) {
        shwoType.value = 'register'
    }
})

 
</script>
<template>
    <van-overlay :show="dialogVisible" :z-index="999" :lock-scroll="false">
        <div class="login_box">
            <div class="login_content">
                <img class="close" src="@/assets/svg/close.svg" alt="" @click="handleCloseMintDialog">
                <loginheader></loginheader>
                <loginInput  v-if="shwoType === 'login'" @handleUserOption="handleUserOption"></loginInput>
                <forgetInput v-if="shwoType === 'forget'" v-model="shwoType"></forgetInput>
                <registerInput v-if="shwoType === 'register'" v-model="shwoType"></registerInput>
            </div>
        </div>
    </van-overlay>
</template>

<style lang="scss" scoped>
.login_box{
    background-color: rgba($color: #000000, $alpha: .3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 70px 60px;
}
.login_content {
    width: 100%;
    // min-height: 100vh;
    // margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .close {
        cursor: pointer;
        width: 27px;
        height: 27px;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>