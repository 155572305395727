import { createI18n } from 'vue-i18n'
import en from './config/en';
import zh from './config/zh';
const messages = {
    en,
    // zh,
  }
// const language = (navigator.language || 'en').toLocaleLowerCase() // 这是获取浏览器的语言
// localStorage.getItem('lang') ||
const i18n = createI18n({
  locale: 'en', // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: 'en', // 设置备用语言
  messages, 
  legacy: false, // 解决legacy mode 报错
})
export default i18n;