<script setup>
import { reactive, ref, watch} from "vue";
import { useI18n } from "vue-i18n";
import { showToast } from 'vant';
import { resetpasswordApi, forgetPasswordApi } from '@/api/index';
import { isEmailAvailable } from '@/utils/index'
const { t } = useI18n();

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
const handleChangeInput = () =>{
    emit('update:modelValue', 'login')
}

const formData = reactive({
  email:'',
  newPassword:'',
  affirmPassword:'',
  verCode:'',  
})

const codeTime = ref(59)
const codeBtnDisable = ref(false)
let timer = null


// 重置验证码
watch(codeTime,(val)=>{
  if (val === 0) {
    codeBtnDisable.value = false
    codeTime.value = 59
    clearInterval(timer)
  }
})

// 验证码
const handleGetCode = () =>{
  if (!isEmailAvailable(formData.email)) {
    showToast(t('alert_tip.success_email'))
    return
  }

  if (!codeBtnDisable.value) {
    codeBtnDisable.value = true
    timer = setInterval(()=>{
      codeTime.value -= 1
    }, 1000)
  }

  forgetPasswordApi({email:formData.email}).then(res =>{
    if (res.data) {
      showToast(`${t('alert_tip.send_code')}`)
    }
  })
}

const isLogin = ref(false)
// 重置
const handleSubmit = async () => {
    isLogin.value = true;
  if (!isEmailAvailable(formData.email)) {
    showToast(t('alert_tip.success_email'))
    return
  }
  if (formData.newPassword !== formData.affirmPassword) {
    showToast(t('alert_tip.password_new'))
    return
  }
  const params = {
    email: formData.email,
    verCode: formData.verCode,
    password: formData.affirmPassword
  }
  const res = await resetpasswordApi(params)
  isLogin.value = false;
  if (res.msg === 'success') {
    showToast(t('alert_tip.success_change'))
    handleChangeInput()
    Object.keys(formData).forEach(item =>{
        formData[item] =  ''
    })
  }
}



</script>
<template>
    <div class="email">
        <input type="text" :placeholder="t('login_input.email_tip')" v-model="formData.email">
    </div>
    <div class="code">
        <input type="text" :placeholder="t('login_input.code_tip')"  v-model="formData.verCode"> 
        <van-button class="code_btn" color="#0500F9" :disabled="codeBtnDisable" round @click="handleGetCode">{{ !codeBtnDisable? `${t('inputCom.send')}`: codeTime +`${t('inputCom.after')}`}}</van-button>
    </div>
    <div class="new_password">
        <input type="password" :placeholder="t('login_input.newPassword_tip')"  v-model="formData.newPassword">
    </div>
    <div class="password">
        <input type="password" :placeholder="t('login_input.password_tip')"  v-model="formData.affirmPassword">
    </div>
    <van-button class="login_btn" round color="#0500F9" :loading="isLogin" @click="handleSubmit" >{{ t('login_input.forget_btn') }}</van-button>
    <div class="left_arrow" @click="handleChangeInput">
        <img src="@/assets/svg/left_arrow.svg" alt="">
    </div>
</template>

<style lang="scss" scoped>
    .email, .code, .new_password, .password{ 
        margin-bottom: 18px;
        width: 100%;
        height: 46px;
        display: flex;
        align-items: center;
        input {
            width: 260px;
            height: 100%;
            border-radius: 23px;
            border: 1px solid #0400f9;
            outline: none;
            padding : 0 20px;
            background-color: transparent;
            font-size: 15px;
            color: #fff;
        }

        input::-ms-input-placeholder {
            text-align: center;
            font-size: 11px;
        }
        input::-webkit-input-placeholder {
            text-align: center;
            font-size: 11px;
        }
        input:focus{
            box-shadow: 0px 0px 15px 3px rgba(26,9,237,0.74);
            caret-color:#0400f9;
        }
    }
    .code {
        display: flex;
        align-items: center;
        input {
          width: 50%;
        }
        .code_btn{
            width: 50%;
            height: 40px;
            margin-left: 5px;
            font-size: 11px;
            color: #fff;
            overflow:hidden;     
            white-space:nowrap;
            text-overflow:ellipsis
        }
    }

    .login_btn{
        width: 260px;
        height: 46px;
        color: #fff;
        font-size: 20px;
        font-family: Gotham-Light;
        text-align: center;
        line-height: 46px;
        margin: 15px 0 30px 0;
    }
    .left_arrow{
        width: 20px;
        height: 10px;
        cursor: pointer;
    }
</style>