import { Overlay, Button, Pagination, Icon, Col, Row, Popup, Toast, List   } from 'vant';

const vantComponent = [
    Overlay,
    Button,
    Pagination,
    Icon,
    Col, 
    Row,
    Popup,
    Toast,
    List
]


export default vantComponent