import i18n from "@/i18n/index.js"
const { t } = i18n.global;
// 钱包交易类型
export const transactionType = [
    {
        id: 1,
        value: t('TransactionType.type1')
    },
    {
        id: 2,
        value: t('TransactionType.type2')
    },
    {
        id: 9,
        value: t('TransactionType.type9')
    },
]


export const webMsgList = [
    {
        code: 4001,
        msg: '用戶拒絕了請求'
    },
    {
        code: 4100,
        msg: '用戶尚未授權所請求的帳戶和/或方法'
    },
]